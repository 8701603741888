// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

import SlimSelect from 'slim-select'

document.addEventListener('DOMContentLoaded', () => {
  const slimSelects = document.querySelectorAll('.slim-select');
  slimSelects.forEach((slimSelect) => {
    new SlimSelect({
      select: slimSelect,
    });
  });
});

